<template>
  <div class="box1">
    <!-- <div v-for="(v, k) in srcList" :key="k">
      <el-button
        class="box"
        type="danger"
        @click="goToPage(v.url, v.key, v.desc)"
        >{{ v.desc }}</el-button
      >
    </div>
    <div class="imgBox">
      <div class="title">
        <div class="text">{{ name }}</div>
        <el-button class="box2" type="danger" @click="goToPath(path)"
          >跳转帆软</el-button
        >
      </div>
      <el-image class="img" :src="url" :preview-src-list="urlList"></el-image>
    </div> -->
    <div>
      <div class="text">销售报表</div>
      <div class="btn">
        <div v-for="(v, k) in srcList" :key="k">
          <el-button
            v-if="v.key==='xsfx'||v.key==='qdjsfx'||v.key==='klfx'||v.key==='jxcfx'"
            class="btn_box"
            type="danger"
            @click="goToPath(v.url)"
            >{{ v.desc }}</el-button
          >
        </div>
      </div>
    </div>
    <div>
      <div class="text">扫码报表</div>
      <div class="btn">
        <div v-for="(v, k) in srcList" :key="k">
          <el-button
            v-if="v.key==='smfx'||v.key==='scclfx'||v.key==='smhdkpfx'"
            class="btn_box"
            type="danger"
            @click="goToPath(v.url)"
            >{{ v.desc }}</el-button
          >
        </div>
      </div>
    </div>
    <div>
      <div class="text">费用报表</div>
      <div class="btn">
        <div v-for="(v, k) in srcList" :key="k">
          <el-button
            v-if="v.key==='hwffx'||v.key==='tghdfx'||v.key==='blhdfx'||v.key==='clhdfx'||v.key==='yxhdfx'||v.key==='fxfx'"
            class="btn_box"
            type="danger"
            @click="goToPath(v.url)"
            >{{ v.desc }}</el-button
          >
        </div>
      </div>
    </div>
    <!-- <el-button class="box" type="danger" @click="goToPage('xsfx')"
      >销售分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('smfx')"
      >扫码分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('scclfx')"
      >市场存量分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('smhdkpfx')"
      >扫码活动开瓶开箱分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('qdjsfx')"
      >渠道建设分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('klfx')"
      >库龄分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('jxcfx')"
      >经销商进销存分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('hwffx')"
      >会务费分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('tghdfx')"
      >推广活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('blhdfx')"
      >包量活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('clhdfx')"
      >陈列活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('yxhdfx')"
      >宴席活动分析</el-button
    >
    <el-button class="box" type="danger" @click="goToPage('fxfx')"
      >费销分析</el-button
    > -->
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'report',
  data() {
    return {
      srcList: [],
      url: '',
      urlList: [],
      name: '',
      path: '',
    };
  },
  created() {
    this.getUrl();
    this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/e4bd341e76554e52811048072d650200.png';
    this.urlList = [this.url];
  },
  methods: {
    getUrl() {
      request.get('/upload/fine/report/getReportButtons').then((res) => {
        if (res.success) {
          this.srcList = res.result;
          this.name = res.result[0].desc;
          this.path = res.result[0].url;
        }
      });
    },
    goToPage(url, key, desc) {
      //   window.open(url, '_blank');
      if (key === 'xsfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/e4bd341e76554e52811048072d650200.png';
      } else if (key === 'smfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/88c523c4c2884fe687cb75175cbd96e5.png';
      } else if (key === 'scclfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/603ebb4e28e5458488295f4954cc30d3.png';
      } else if (key === 'smhdkpfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/3676cdcd6b984ab3b5a07171b50f2286.png';
      } else if (key === 'qdjsfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/ce052ce0ecc44a2a8d5019f3ac767ad3.png';
      } else if (key === 'klfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/93e555b631b542ef8a8709cf18b2d554.png';
      } else if (key === 'jxcfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/1eef68ec67f84df39b5741ead4c43336.png';
      } else if (key === 'hwffx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/632f6b4504764d9cab0d390c761b32cc.png';
      } else if (key === 'tghdfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/b8adb5bf8136449c93efd5b35ef47985.png';
      } else if (key === 'blhdfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/076391d479c54579858bbe218d1523ff.png';
      } else if (key === 'clhdfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/91163f16d1c1455dae81d9d397dc6895.png';
      } else if (key === 'yxhdfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/500f2699934a4822bf028e1b91749db1.png';
      } else if (key === 'fxfx') {
        this.url = 'https://xf-crm.obs.cn-north-4.myhuaweicloud.com/png/20241029/3f54f9ccef034ccb9ccf0664c7e94e22.png';
      }
      this.name = desc;
      this.path = url;
      this.urlList = [this.url];
    },
    goToPath(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
.box1 {
  position: relative;
}
.btn{
  display: flex;
  margin-bottom: 50px;
}
.btn_box{
  margin-right: 10px;
  width: 150px;
}
.box {
  display: block;
  margin-left: 0 !important;
  margin-bottom: 10px;
  width: 150px;
}
.imgBox {
  position: absolute;
  top: 0;
  left: 170px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.text {
  font-size: 24px;
}
.box2 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
.img {
  width: 100%;
  height: 100%;
}
</style>
