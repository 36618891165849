var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box1" }, [
    _c("div", [
      _c("div", { staticClass: "text" }, [_vm._v("销售报表")]),
      _c(
        "div",
        { staticClass: "btn" },
        _vm._l(_vm.srcList, function (v, k) {
          return _c(
            "div",
            { key: k },
            [
              v.key === "xsfx" ||
              v.key === "qdjsfx" ||
              v.key === "klfx" ||
              v.key === "jxcfx"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn_box",
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.goToPath(v.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(v.desc))]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "text" }, [_vm._v("扫码报表")]),
      _c(
        "div",
        { staticClass: "btn" },
        _vm._l(_vm.srcList, function (v, k) {
          return _c(
            "div",
            { key: k },
            [
              v.key === "smfx" || v.key === "scclfx" || v.key === "smhdkpfx"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn_box",
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.goToPath(v.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(v.desc))]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "text" }, [_vm._v("费用报表")]),
      _c(
        "div",
        { staticClass: "btn" },
        _vm._l(_vm.srcList, function (v, k) {
          return _c(
            "div",
            { key: k },
            [
              v.key === "hwffx" ||
              v.key === "tghdfx" ||
              v.key === "blhdfx" ||
              v.key === "clhdfx" ||
              v.key === "yxhdfx" ||
              v.key === "fxfx"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn_box",
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.goToPath(v.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(v.desc))]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }